import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, CardBody, Row, Col, CardTitle, CardText } from 'reactstrap';
import Select from 'react-select';
import * as dealersActions from '../../store/dealers/actions';
import { useDispatch, useSelector } from 'react-redux';
import Map from './Map';
import useClipboard from 'react-hook-clipboard';
import { useTranslation } from 'react-i18next';
import toastr from 'toastr';
import colors from '../CommonStyle/colors';

const DealerFinder = ({ lead }) => {
  const [selectedCity, setSelectedCity] = useState(null);

  const loading = useSelector((state) => state.Dealer.loading);
  const dealers = useSelector((state) => state.Dealer.dealers);
  const cities = useMemo(
    () => [...new Set(dealers.map(({ city }) => city))],
    [dealers],
  );
  const filteredDealers = useCallback(
    (selectedCity) => {
      if (!selectedCity) return [];
      return dealers.filter((dealer) => dealer.city === selectedCity.value);
    },
    [dealers],
  );
  const markers = useMemo(
    () =>
      filteredDealers(selectedCity).map(
        ({ id, lat, lng, company, address, place, zipCode, city }) => ({
          id,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          title: company,
          description: `${address}, ${place} - ${zipCode} - ${city}`,
        }),
      ),
    [filteredDealers, selectedCity],
  );

  const [clipboard, copyToClipboard] = useClipboard();

  const dispatch = useDispatch();

  useEffect(() => {
    lead?.carBrand &&
      dispatch(dealersActions.syncDealers({ carBrand: lead.carBrand }));
  }, [lead]);

  const handleCopyDealerId = useCallback((company, dealerId) => {
    copyToClipboard(dealerId);
    toastr.success('ID copied to clipboard', company);
  }, []);

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <div className="avatar-sm float-sm-left mr-3">
          <span className="avatar-title rounded-circle bg-info font-size-24">
            <i className={`bx bx-car text-light`}></i>
          </span>
        </div>
        <CardTitle className="mt-2 mr-3 float-sm-left font-size-18">
          {t('City')}:
        </CardTitle>
        <div className="mr-2 mb-2 d-inline-block">
          <div className="mt-1 d-none d-lg-block p-0">
            <Select
              isMulti={false}
              isSearchable
              value={selectedCity}
              onChange={(value) => setSelectedCity(value)}
              options={cities.map((city) => ({
                value: city,
                label: city,
              }))}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={{
                control: (base) => ({
                  ...base,
                  width: '200px',
                  border: `1px solid ${colors.dark}`,
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: '9999 !important',
                }),
              }}
            />
          </div>
        </div>
        <Row className="mt-3">
          <Col md="6" className="overflow-auto" style={{ height: '600px' }}>
            {filteredDealers(selectedCity).map(
              ({ id, company, place, address, zipCode, city, dealerId }) => (
                <Card key={id}>
                  <h5 className="card-header border-bottom text-uppercase">
                    {company}
                  </h5>
                  <CardBody>
                    <CardTitle className="mt-0">{place}</CardTitle>
                    <CardText>
                      {address}
                      <br />
                      {zipCode} - {city}
                    </CardText>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary waves-effect waves-light float-right"
                      onClick={() => handleCopyDealerId(company, dealerId)}>
                      {t('Copy Dealer ID')}
                    </button>
                  </CardBody>
                </Card>
              ),
            )}
          </Col>
          <Col md="6">{selectedCity && <Map markers={markers} />}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DealerFinder;
