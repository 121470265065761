import '@availity/yup';
import * as yup from 'yup';

const COMMA_SEPARATED_VALUES_REGEXP =
  /^[a-zA-Z\u00C0-\u017F0-9\s'\-./]+(?:(?:,)[a-zA-Z\u00C0-\u017F0-9\s'\-./]+)*[a-zA-Z\u00C0-\u017F0-9\s'\-./]+$/g;

const formValidation = yup.object().shape({
  name: yup.string().required(),
  customerId: yup.string().required(),
  campaignIds: yup.array().of(yup.string()).required().min(1),
  qualified: yup.string().required(),
  leadFormId: yup.string().required(),
  sector: yup.string().required(),
  priority: yup.number().required().min(1).max(10),
  monetizationOrdersAllowed: yup.mixed().required(),
  messageTemplateId: yup.string().required(),
  costNegativeClosure: yup.number().when('qualified', {
    is: 'yes',
    then: () => yup.number().required().min(0),
  }),
  maxCallAttempts: yup.number().when('qualified', {
    is: 'yes',
    then: () => yup.number().required().min(2),
  }),
  phoneExchangeRoute: yup.string().when('qualified', {
    is: 'yes',
    then: () => yup.string().required(),
  }),
  phoneExchangeQueue: yup.string().when('qualified', {
    is: 'yes',
    then: () => yup.string().required(),
  }),
  sendMessageAfterRecall: yup
    .number()
    .min(0)
    .when('maxCallAttempts', (maxCallAttempts, schema) =>
      schema.max(parseInt(maxCallAttempts) - 1),
    ),
  recallMessageTemplateId: yup
    .string()
    .when('sendMessageAfterRecall', (sendMessageAfterRecall, schema) =>
      parseInt(sendMessageAfterRecall) > 0 ? schema.required() : schema,
    ),
  callStopwatch: yup.number().min(0).max(10),
  negativeReasons: yup.string().matches(COMMA_SEPARATED_VALUES_REGEXP, {
    excludeEmptyString: true,
  }),
  negativeWebService: yup.string().when('qualified', {
    is: 'yes',
    then: () => yup.string().required(),
  }),
  negativeMessageTemplateId: yup.string().required(),
  negativeMessageSendDelay: yup.number().when('negativeMessageTemplateId', {
    is: (value) => value && value !== 'none',
    then: () => yup.number().required().min(1),
  }),
  expiredMessageTemplateId: yup.string().required(),
});

export default formValidation;
